import React from "react";
import VehicleIcon from "./VehicleIcons";

const VehicleButton = ({ type, isSelected, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center space-x-2 pb-3  transition-colors duration-200 ${
        isSelected
          ? "text-brand1-500 border-b-2 border-brand1-500"
          : "text-brand2-500 hover:text-brand1-500"
      }`}
    >
      <VehicleIcon type={type} />
      <span className=" capitalize text-xs  lg:text-sm">{type}</span>
    </button>
  );
};

export default VehicleButton;
