import React from "react";
// import ConfiguratoreTest from "./components/ConfiguratoreTest";
import Configuratore from "./components/Configuratore";

import "./index.css";

function App() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {/* <Configuratore /> */}
      <Configuratore />
    </div>
  );
}

export default App;
