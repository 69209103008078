export const SearchButton = () => {
  return (
    <button
      href="_blanck"
      onClick={() => window.open("_blank")}
      type="button"
      className="place-items-end rounded-md bg-brand1-500 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-500 hover:border hover:border-brand1-500 hover:bg-white hover:text-brand1-500 "
    >
      Button text
    </button>
  );
};
