export const getItems = async (
  configType,
  tecdocType,
  vehicleType,
  idBrand = null,
  idModel = null
) => {
  if (!configType || !tecdocType || !vehicleType) {
    const error = new Error(
      "Parametro mancante: verifica configType, tecdocType e vehicleType"
    );
    error.statusCode = 400;
    throw error;
  }

  const baseUrl = "https://dev20.vmv-web.it/api";

  const configuratore = {
    confLuci: "conf-luci",
    confSpazzole: "conf-spazzole",
    confBallast: "conf-ballast",
  };

  const versione = "v1";

  const getTecdoc = {
    marche: "getTecdocManufacturers.php",
    modelli: "getTecdocModels.php",
    veicoli: "getTecdocVehicles.php",
  };

  const token =
    "RoV0Sc6FAQf6ZStiLfHfklgSP6EdYRKWQYF6BcgAavSBt7LDkzlfsZC7D1ytUXvL";
  // settare la lingua dall'iframe
  const lang = navigator.language || navigator.userLanguage;
  const formattedLang = lang.slice(0, 2).toUpperCase();
  const type = {
    auto: "auto",
    moto: "moto",
    truck: "truck",
  };

  let url = "";

  if (tecdocType === "marche") {
    url = `${baseUrl}/${configuratore[configType]}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      formattedLang
    )}&vType=${encodeURIComponent(type[vehicleType])}`;
  } else if (tecdocType === "modelli" && idBrand) {
    url = `${baseUrl}/${configuratore[configType]}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      formattedLang
    )}&vType=${encodeURIComponent(
      type[vehicleType]
    )}&manufacturerId=${idBrand}`;
  } else if (tecdocType === "veicoli" && idModel) {
    url = `${baseUrl}/${configuratore[configType]}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      formattedLang
    )}&vType=${encodeURIComponent(type[vehicleType])}&modelId=${idModel}`;
  }
  console.log("URL generato:", url); // Verifica l'URL generato

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Errore nella risposta dal server");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Errore nel caricamento dei dati:", error);
    if (!error.statusCode) error.statusCode = 500;
    throw error;
  }
};
