import React, { useState, useEffect } from "react";
import VehicleButton from "./interactive-elements/VehicleButton";
import VehicleImage from "./interactive-elements/VehicleImage";
import SelectCombobox from "./interactive-elements/SelectCombobox";
import { getItems } from "../utils/api";
import { SearchButton } from "./interactive-elements/SearchButton";

const Configurator = () => {
  const [configType] = useState("confLuci");
  const [vehicleType, setVehicleType] = useState("auto");

  const [selections, setSelections] = useState({
    brand: null,
    model: null,
    vehicle: null,
  });

  const [options, setOptions] = useState({
    brands: [],
    models: [],
    vehicles: [],
  });

  const levels = [
    { key: "brand", label: "Marca", tecdocType: "marche" },
    { key: "model", label: "Modello", tecdocType: "modelli" },
    { key: "vehicle", label: "Veicolo", tecdocType: "veicoli" },
  ];

  const loadOptions = async (levelKey, parentId = null) => {
    const levelConfig = levels.find((level) => level.key === levelKey);
    try {
      const data = await getItems(
        configType,
        levelConfig.tecdocType,
        vehicleType,
        levelKey === "model" ? parentId : selections.brand?.id,
        levelKey === "vehicle" ? parentId : selections.model?.id
      );
      const loadedOptions = data.map((group) => ({
        heading: group.group.name,
        items: group.items.map((item) => ({
          id: item.id,
          name: item.title,
          subTitle: item.subTitle,
        })),
      }));

      setOptions((prevOptions) => ({
        ...prevOptions,
        [`${levelKey}s`]: loadedOptions,
      }));
    } catch (error) {
      console.error(
        `Errore nel caricamento delle opzioni per ${levelKey}:`,
        error
      );
    }
  };

  useEffect(() => {
    loadOptions("brand");
    setSelections({ brand: null, model: null, vehicle: null });
  }, [vehicleType]);

  const handleSelect = (levelKey, selectedOption) => {
    const newSelections = { ...selections, [levelKey]: selectedOption };

    if (levelKey === "brand") {
      newSelections.model = null;
      newSelections.vehicle = null;
      setOptions((prevOptions) => ({
        ...prevOptions,
        models: [],
        vehicles: [],
      }));
      loadOptions("model", selectedOption?.id);
    } else if (levelKey === "model") {
      newSelections.vehicle = null;
      setOptions((prevOptions) => ({ ...prevOptions, vehicles: [] }));
      console.log("Carico veicoli per idModel:", selectedOption?.id);

      loadOptions("vehicle", selectedOption?.id);
    }

    setSelections(newSelections);
  };

  return (
    <div className="max-w-5xl mx-auto  px-6 lg:px-8 py-8 bg-white shadow-md rounded-lg min-w-xs">
      <h1 className="text-3xl lg:text-4xl font-bold text-center mb-6 lg:mb-8">
        Configuratore Luci
        {/* rendi dinamico il titolo */}
      </h1>

      <div className="flex justify-center items-center gap-3.5 lg:gap-5 mb-4 border-b">
        {["auto", "moto", "truck"].map((type) => (
          <VehicleButton
            key={type}
            type={type}
            isSelected={vehicleType === type}
            onClick={() => setVehicleType(type)}
            className="text-center hover:fill-brand1-500"
          />
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8  m-4 lg:mr-8">
        <div className="flex justify-center max-w-full">
          <VehicleImage
            vehicleType={vehicleType}
            className="w-full w-1/2 p-4 lg:w-2/3 h-auto object-contain"
          />
        </div>
        <div>
          <div className="space-y-4 w-full mb-8">
            {levels.map((level) => (
              <SelectCombobox
                key={level.key}
                label={level.label}
                options={options[`${level.key}s`]}
                selectedOption={selections[level.key]}
                onSelect={(selectedOption) =>
                  handleSelect(level.key, selectedOption)
                }
                disabled={
                  (level.key === "model" && !selections.brand) ||
                  (level.key === "vehicle" && !selections.model)
                }
              />
            ))}
            <div>
              <SearchButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurator;
