import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

const SelectCombobox = ({
  label,
  options,
  selectedOption,
  onSelect,
  disabled,
}) => {
  const [query, setQuery] = useState("");

  const filteredOptions = options
    .map((group) => ({
      heading: group.heading,
      items: group.items.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      ),
    }))
    .filter((group) => group.items.length > 0);

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={(option) => {
        setQuery("");
        onSelect(option);
      }}
      className="w-full my-3.5"
      disabled={disabled}
    >
      <Label className="block text-xs  lg:text-sm font-medium text-gray-900">
        {label}
      </Label>
      <div className="relative mt-2">
        <ComboboxInput
          className={`w-full rounded-md border ${
            disabled ? "bg-gray-100 text-gray-400" : "bg-white text-gray-900"
          } active:border-brand1-500 py-1.5 px-3 shadow-sm focus:ring-inset sm:text-sm`}
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery("")}
          displayValue={(option) =>
            option
              ? `${option.name}${option.subTitle ? `  ${option.subTitle}` : ""}`
              : ""
          }
          disabled={disabled}
        />
        <ComboboxButton
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          disabled={disabled}
        >
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredOptions.length > 0 && !disabled && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-slate-100 py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredOptions.map((group) => (
              <div key={group.heading}>
                <div className="px-3 py-1 text-gray-700 font-semibold">
                  {group.heading}
                </div>
                {group.items.map((option) => (
                  <ComboboxOption
                    key={option.id}
                    value={option}
                    className="group relative cursor-default select-none py-1 pl-3 pr-9  data-[focus]:bg-brand1-500  data-[focus]:outline-none"
                  >
                    <div className="flex gap-2 pl-6">
                      <span className="block truncate text-black group-data-[selected]:font-semibold group-data-[focus]:text-white ">
                        {option.name}
                      </span>
                      {option.subTitle && (
                        <span className="text-sm text-slate-600 group-data-[focus]:text-slate-200  truncate">
                          {option.subTitle}
                        </span>
                      )}
                    </div>
                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </ComboboxOption>
                ))}
              </div>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
};

export default SelectCombobox;
